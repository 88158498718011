<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title
      | {{ goal ? "Change Goal" : "Add Goal" }}
    b-button(icon-left="times" @click="close()")
  section.modal-card-body
    goal-form(:goal="goal" :show-save="false" ref="form")
  footer.modal-card-foot
    b-button.is-danger(@click="remove" v-if="goal" :loading="isSaving") Delete Goal
    b-button.is-primary(@click="save" :loading="isSaving")
      | {{ goal ? "Update Goal" : "Set Goal" }}
</template>

<script>
import GoalForm from '@/components/GoalForm'

export default {
  components: {
    GoalForm
  },
  props: {
    goal: {
      type: Object,
      optional: true,
      default: null
    }
  },
  data() {
    return {
      isSaving: false
    }
  },
  methods: {
    save() {
      const { form } = this.$refs
      if (!form) {
        return
      }
      this.isSaving = true
      form
        .save()
        .then(() => {
          this.isSaving = false
          if (form.errorMessage === null) {
            this.close()
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    remove() {
      const { form } = this.$refs
      if (!form) {
        return
      }
      this.isSaving = true
      form
        .delete()
        .then(() => {
          this.isSaving = false
          if (form.errorMessage === null) {
            this.close()
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    close() {
      if (this.isSaving) {
        return
      }
      this.$parent.close()
    }
  }
}
</script>
<style lang="sass" scoped>
.modal-card-foot
  display: flex
  justify-content: space-between
.modal-card, .modal-card-body
  overflow: visible
</style>
