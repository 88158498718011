<template lang="pug">
.goal-form(v-if="form")
  .columns
    .column.is-6
      b-field(label="I want to reach...")
        currency-input.input.currency-input(v-model="form.target" :currency="currencyOption" :precision="currencyPrecision")
    .column.is-6
      b-field(label="By")
        b-datepicker(v-model="form.goalDate" :date-parser="dateParser" :date-formatter="dateFormatter" :append-to-body="true")
  a(@click="showAdvanced = true" v-if="!showAdvanced") Change goal currency or start amount...
  .columns.advanced-settings(v-if="showAdvanced")
    .column.is-6
      .field.has-addons
        .label Goal Currency
        .control.is-expanded
          currency-select(:value="chosenCurrency" @input="updateCurrency" placeholder="" prefix="")
        .control.is-expanded(v-if="hasSubunits")
          b-select(v-model="form.subunit" :expanded="true")
            option(v-for="subunit in orderedSubunits" :value="subunit.id") {{ subunit.description }}
    .column.is-6
      b-field(label="Starting amount" :type='{"is-danger": !startAmountValid}' :message='{"Larger than your last entry!": !startAmountValid}')
        currency-input.input.currency-input(v-model="form.startAmount" @blur="orZero" :currency="currencyOption" :precision="currencyPrecision")
  b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage")
    p.content(v-if="errorMessage === 'not-unique'") You already have a goal set for that day!
    p.content(v-else-if="errorMessage === 'invalid-date'") You need a goal date!
    p.content(v-else) An error occurred: {{ errorMessage }}
  b-button.is-primary(v-if="showSave" @click="save" :loading="isSaving")
    | {{ isNew ? "Set Goal" : "Update Goal" }}
</template>

<script>
import { cloneDeep, round } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from '@/dayjs'

import subunits from '@/mixins/subunits'
import CurrencySelect from '@/components/CurrencySelect'
import { dateParser, dateFormatter, currencyWithSubunit } from '@/misc/helpers'

export default {
  components: {
    CurrencySelect
  },
  mixins: [subunits],
  props: {
    goal: {
      type: Object,
      optional: true,
      default: null
    },
    showSave: {
      type: Boolean,
      optional: true,
      default: true
    }
  },
  data() {
    return {
      isNew: false,
      form: null,
      errorMessage: null,
      isSaving: false,
      showAdvanced: false
    }
  },
  computed: {
    ...mapState('currencies', ['activeCurrency']),
    ...mapGetters('currencies', ['getCurrencyById', 'activeSubunit']),
    ...mapGetters('entries', ['lastEntry']),
    subunit() {
      return this.form.subunit
    },
    chosenCurrency() {
      return this.getCurrencyById(this.form.currency)
    },
    startAmountValid() {
      const { maxStartAmount, form } = this
      if (maxStartAmount === null) {
        return true
      }
      if (form === null) {
        return true
      }
      if (form.startAmount === null) {
        return true
      }
      const precision = (form.startAmount.toString().split('.')[1] || '').length
      return form.startAmount <= round(maxStartAmount, +precision)
    }
  },
  watch: {
    goal: {
      immediate: true,
      deep: true,
      handler(goal) {
        if (goal) {
          this.form = this.editGoalForm(goal)
        } else {
          this.newGoalForm().then(form => {
            this.form = form
          })
        }
      }
    }
  },
  asyncComputed: {
    maxStartAmount() {
      const { form } = this
      if (!form) {
        return null
      }
      const { currency, subunit } = form
      return this.startAmount(currency, subunit)
    }
  },
  methods: {
    ...mapActions('goals', ['saveGoal', 'deleteGoal']),
    ...mapActions('currencies', ['getExchangeRateOn']),
    orZero() {
      if (this.form.startAmount === null) {
        this.form.startAmount = 0
      }
    },
    async startAmount(currency, subunit) {
      const { netWorth: startAmount = 0, date } = this.lastEntry()
      const rate = await this.getExchangeRateOn({
        date,
        currency: currencyWithSubunit(currency, subunit)
      })
      return startAmount * rate
    },
    async newGoalForm() {
      this.isNew = true
      const currency = this.activeCurrency.id
      const subunit = this.activeSubunit ? this.activeSubunit.id : null
      return {
        goalDate: dayjs()
          .add(1, 'year')
          .startOf('year')
          .toDate(),
        target: 0,
        targetType: 'networth',
        currency,
        subunit,
        startAmount: await this.startAmount(currency, subunit)
      }
    },
    editGoalForm(goal) {
      this.isNew = false
      return cloneDeep(goal)
    },
    dateFormatter,
    dateParser,
    updateCurrency(currency) {
      this.form.currency = currency.id
      // Check for existing cross-compatible subunit
      // e.g changing between types of bullion, keeping the unit
      const { subunit } = this.form
      if (subunit && 'subunits' in currency && subunit in currency.subunits) {
        // Applies to the new one, skip
      } else if ('subunits' in currency) {
        // See if the currency has subunits and a default subunit
        const defaultSubunit = Object.values(currency.subunits).find(
          ({ isDefault }) => isDefault
        )
        // Set the default subunit
        if (defaultSubunit) {
          this.form.subunit = defaultSubunit.id
        } else {
          // Fallback: No subunit
          this.form.subunit = null
        }
      } else {
        // Fallback: No subunit
        this.form.subunit = null
      }
      // Redo the goal start amount
      if (this.form.startAmount === this.maxStartAmount) {
        this.startAmount(this.form.currency, this.form.subunit).then(amount => {
          this.form.startAmount = amount
        })
      }
    },
    save() {
      this.errorMessage = null
      this.isSaving = true
      return this.saveGoal(this.form)
        .catch(error => {
          this.errorMessage = error.message
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    delete() {
      this.errorMessage = null
      this.isSaving = true
      return this.deleteGoal(this.goal.id)
        .catch(error => {
          this.errorMessage = error.message
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>
<style lang="sass" scoped>
$currency-select-width: 70px
.has-addons
  flex-wrap: wrap
  .label
    width: 100%
.multiselect
  max-width: 100%
.currency-control
  width: $currency-select-width
  ::v-deep
    .multiselect__single
      margin-left: 0
      padding-left: 0
    .option-id
      white-space: nowrap
      margin-left: 0
      display: inline-block
      width: 100%
      text-align: center
    .multiselect__select
      display: none
    .multiselect__tags
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      width: $currency-select-width
      max-width: $currency-select-width
      cursor: pointer
      padding-right: 8px
    .multiselect__content-wrapper
      width: 300px
      border-radius: 5px
</style>
