<template lang="pug">
.goal-display
  .goal-description(v-if="targetInBaseCurrency")
    currency-output(:value="targetInBaseCurrency" :date="entry.date")
    | &nbsp;by &nbsp;
    span.goal-date {{ goal.date | formatDate('short') }}
  .goal-description(v-else)
    | Loading...
  .action-slot
    slot
  b-progress.goal-progress(v-if="showProgress" :value="progress || 0" :show-value="progress !== null" format="percent" type="is-primary")
  .goal-status(v-if="showProgress && progress === null") Loading...
  .goal-status(v-else-if="showProgress && isComplete")
  .goal-status(v-else-if="showProgress && isPast && nextEntryPastGoal")
    | You missed your goal by&nbsp;
    currency-output(:value="amountLeft" :date="entry.date")
  .goal-status(v-else-if="showProgress")
    | {{ goal.goalDate | timeUntil(date) }} and &nbsp;
    currency-output(:value="amountLeft" :date="entry.date")
    | &nbsp; left to go

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'

import { formatDate, timeUntil } from '@/misc/filters'
import { currencyWithSubunit } from '@/misc/helpers'

export default {
  components: {},
  filters: {
    formatDate,
    timeUntil
  },
  props: {
    goal: Object,
    date: Date,
    showProgress: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('entries', ['lastEntry', 'nextEntry']),
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    entry() {
      return this.lastEntry(this.date)
    },
    isComplete() {
      const { amountLeft, entry } = this
      if (!entry) {
        return false
      }
      return amountLeft === 0
    },
    isPast() {
      return dayjs(this.goal.goalDate).isBefore()
    },
    nextEntryPastGoal() {
      const nextEntry = this.nextEntry(
        dayjs(this.date)
          .add(1, 'day')
          .toDate()
      )
      const { goal } = this
      const { goalDate } = goal
      if (nextEntry === null) {
        return true
      }
      const { date } = nextEntry
      return dayjs(date).isSameOrAfter(goalDate)
    },
    goalCurrency() {
      const { goal } = this
      const { currency, subunit } = goal
      return currencyWithSubunit(currency, subunit)
    },
    entryAmount() {
      const { entry, rate } = this
      if (entry === null || rate === null) {
        return 0
      }
      const { netWorth } = entry
      return netWorth * rate
    },
    targetInBaseCurrency() {
      const { goal, rate } = this
      if (goal === null || rate === null) {
        return null
      }
      return goal.target / rate
    },
    amountLeft() {
      const { targetInBaseCurrency, entry } = this
      if (entry === null || targetInBaseCurrency === null) {
        return null
      }
      const amountLeft = targetInBaseCurrency - entry.netWorth
      return Math.max(amountLeft, 0)
    },
    progress() {
      const { target, startAmount } = this.goal
      const { entryAmount } = this
      if (entryAmount === null) {
        return null
      }
      const progress = (entryAmount - startAmount) / (target - startAmount)
      return Math.max(Math.min(Math.round(progress * 100), 100), 0)
    }
  },
  asyncComputed: {
    rate() {
      const { entry, goalCurrency } = this
      if (entry === null || goalCurrency === null) {
        return null
      }
      return this.getExchangeRateOn({
        date: entry.date,
        currency: goalCurrency
      })
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn'])
  }
}
</script>
<style lang="sass" scoped>
.goal-display
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-bottom: 0.25rem
.goal-description
  flex-grow: 1
  margin-bottom: 0.25rem
  padding-right: 0.5rem
.action-slot
  margin-bottom: 0.25rem
.goal-progress
  width: 100%
  margin-top: 0.25rem
.progress-wrapper
  margin-bottom: 0.5rem
.goal-status
  flex-grow: 1
  text-align: center
  font-size: 0.9rem
@media screen and (min-width: 500px)
  .goal-description
    font-size: 1.1rem
  .goal-status
    font-size: 1rem
</style>
